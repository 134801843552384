import React from 'react'
import css from './Footer.module.css'
import {InboxIcon, PhoneIcon, LocationMarkerIcon,  UserIcon, StarIcon} from "@heroicons/react/outline"
import Logo from "../../assets/logo.png"

const Footer = () => {
  return (
<div className={css.cFooterWrapper}>
<hr/>

<div className={css.cFooter}>
<div className={css.logo}>

<img src={Logo} alt="" />
<span>Gustavo La Unión</span>

</div>

<div className={css.block}>
<div className={css.detail}>
<span>Contacto</span>
<span className={css.pngLine}>
<LocationMarkerIcon className={css.icon} />
<span>  Av. Circunvalación Norte. Agustín Tosco 2655, Córdoba.  </span> </span>

<span className={css.pngLine}>
<InboxIcon className={css.icon}/>
<a href="mailto:gustavocarrizo@neuyserv.com">Contacto Vía Mail</a>

</span>

<span className={css.pngLine}>
<PhoneIcon className={css.icon}/>
<a href="tel:3517664103">Llamar y consultar por stock</a></span>

</div>

</div>

<div className={css.block}>
<div className={css.detail}>
<span>Compañía</span>
<span className={css.pngLine}>
  <UserIcon className={css.icon}/>
  <a target="blank" href="https://launionneumaticos.com.ar/">La Unión Neumáticos</a>
</span>

</div>

</div>

<div className={css.block}>
  <div className={css.detail}>
<span>Locaciones</span>
<span className={css.pngLine}>
 <StarIcon className={css.icon}/>
<a target="blank" href="mailto:cordoba@neuyserv.com">Truck Center Córdoba</a></span>
  


  </div>
</div>



</div>


<div className={css.copyRight}>
<span>La Unión neumáticos</span>
<span>Derechos reservados</span>

</div>

</div>
  )
}

export default Footer