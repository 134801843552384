import img1 from '../assets/img1.png'
import img2 from '../assets/img2.png'
import img3 from '../assets/img3.png'
import img4 from '../assets/img4.png'
import img5 from '../assets/img5.png'
import img6 from '../assets/img6.png'
import img7 from '../assets/img7.png'
import img8 from '../assets/img8.png'
import img9 from '../assets/img9.png'
import img10 from '../assets/img10.png'
import telas from '../assets/telas.png'
import offroad from '../assets/offroad.png'

export const SliderProducts = [{
    name: 'PRECURADO',
    detail: 'Banda Lisa',
    price: '',
    img: img1
},
{
    name: 'PRECURADO',
    detail: 'Taco',
    price: '',
    img: img2
},
{
    name: 'RECAUCHUTAJE',
    detail: 'Consultar',
    price: '',
    img: img6
}, 

{
    name: 'TELAS',
    detail: 'Refuerzo',
    price: '',
    img: telas
}, 

{

    name: 'OFF ROAD',
    detail: 'Consultar',
    price: '',
    img: offroad
}
]
export const ProductsData = [{
    name: 'CAMIÓN',
    detail: 'Banda Ap Light',
    price: '',
    img: img1,
    type: 'arreglo',
},
{
    name: 'CAMIÓN',
    detail: 'CT 162',
    price: '',
    img: img2,
    type: 'arreglo',
},
{
    name: 'CAMIÓN',
    detail: 'Beltak 358 - G359',
    price: '',
    img: img3,
    type: 'arreglo',
},
{
    name: 'AGRÍCOLA',
    detail: 'G8',
    price: '',
    img: img4,
    type: 'arreglo',
},
{
    name: 'AGRÍCOLA',
    detail: '12 telas',
    price: '',
    img: img5,
    type: 'recauchutaje',
},
{
    name: 'AGRÍCOLA',
    detail: '16 telas',
    price: '',
    img: img6,
    type: 'recauchutaje',
},
{
    name: "AGRÍCOLA",
    details: '12 a 16 telas',
    price: '',
    img: img7,
    type: 'recauchutaje'
}
,
{
    name: "VIAL",
    details: 'MT L2',
    price: '',
    img: img8,
    type: 'nuevo'
},
{
    name: "VIAL",
    details: 'ROC L3',
    price: '',
    img: img9,
    type: 'nuevo'
},
{
    name: "VIAL",
    details: 'L5',
    price: '',
    img: img10,
    type: 'nuevo'
},


]