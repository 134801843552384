import React from 'react'
import css from './Hero.module.css'
import HeroImg from '../../assets/hero.png'



const Hero = () => {
  return (
  <div className={css.container} >

{/*  Left side */}

<div className={css.h_sides}>
<span className={css.text1}> REPARACIONES INTEGRALES </span>
<span> Equipos especializados </span> 
</div>
 
 {/* Middle section */}
 
 <div className={css.wrapper}> 

 
 <img src={HeroImg} alt="" width={600} /> 
 </div>




{/* Right side */}
<div className={css.h_sides} >


<div className={css.traffic}>
<span> Goodyear </span>
<span> Centro Oficial </span>
</div>


</div>


  </div>
  )
}

export default Hero