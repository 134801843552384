import React from 'react'
import css from './Virtual.module.css'
import ReactCompareImage from 'react-compare-image'
import Before from '../../assets/before.png'
import After from '../../assets/after.png'

const Virtual = () => {
  return (
    <div className={css.Virtual}>  
    <div className={css.left}>
<span>ELEGÍ LA OPCIÓN MÁS SEGURA</span>
<span> Empresa con más de 50 años de experiencia </span>
<span> Presupuesto sin cargo </span>

    </div>

<div className={css.right}> 
    <div className={css.wrapper}>

    <ReactCompareImage leftImage={Before} rightImage={After}/>
    
    </div>
</div>

    </div>
  )
}

export default Virtual